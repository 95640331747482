import { render, staticRenderFns } from "./TicketStatusesForm.vue?vue&type=template&id=d3b86842&scoped=true&"
import script from "./TicketStatusesForm.vue?vue&type=script&lang=js&"
export * from "./TicketStatusesForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3b86842",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VDivider,VRow})
