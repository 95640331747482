<template>
  <v-container fluid class="px-lg-10">
    <TicketsStatusesSearch @search="() => $refs.table.refresh()" />
    <BaseTable
      ref="table"
      title="Stati"
      module="ticketsStatuses"
      :headers="headers"
      :actions="actions"
      sortby="code"
      :sort-desc="false"
    >
      <template v-slot:item.opening="{ item }">
        {{ item.opening ? 'SI' : 'NO' }}
      </template>
      <template v-slot:item.closing="{ item }">
        {{ item.closing ? 'SI' : 'NO' }}
      </template>
      <template v-slot:item.reschedulable="{ item }">
        {{ item.reschedulable ? 'SI' : 'NO' }}
      </template>
      <template v-slot:item.revocable="{ item }">
        {{ item.revocable ? 'SI' : 'NO' }}
      </template>
      <template v-slot:item.active="{ item }">
        {{ item.active ? 'SI' : 'NO' }} </template
      ><template v-slot:item.suspend="{ item }">
        {{ item.suspend ? 'SI' : 'NO' }}
      </template>
      <template v-slot:actions>
        <v-btn
          v-if="canUser('tickets_statuses', 'create')"
          color="green"
          dark
          tile
          @click="openModal"
        >
          Aggiungi stato
        </v-btn>
      </template>
    </BaseTable>
    <v-dialog
      v-model="dialog"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-card-title v-if="editMode"> Modifica Dettagli</v-card-title>
        <v-card-title v-else>Crea nuovo stato</v-card-title>
        <v-card-text>
          <TicketStatusesForm
            :title="null"
            :edit="editMode"
            @submitted="onSubmitted"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import TicketStatusesForm from '@components/tickets/statuses/TicketStatusesForm.vue'
import TicketsStatusesSearch from '@components/tickets/statuses/TicketsStatusesSearch.vue'
import { authComputed } from '@state/helpers.js'
export default {
  name: 'TicketStatusesSearch',
  components: { TicketStatusesForm, TicketsStatusesSearch, BaseTable },
  page: {
    title: 'Stati ticket',
  },
  data() {
    return {
      dialog: false,
      editMode: false,
      headers: [
        {
          text: 'Codice',
          value: 'code',
        },
        {
          text: 'Apertura',
          value: 'opening',
        },
        {
          text: 'Chiusura',
          value: 'closing',
        },
        {
          text: 'Rischedulabile',
          value: 'reschedulable',
        },
        {
          text: 'Revoca',
          value: 'revocable',
        },
        {
          text: 'Sospensione',
          value: 'suspend',
        },
        {
          text: 'Commessa',
          value: 'macros',
        },
        {
          text: 'Categoria',
          value: 'categories',
        },

        {
          text: 'Attivo',
          value: 'active',
        },
      ],
      actions: [
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: () => this.canUser('tickets_statuses', 'update'),
          handler: this.openModal,
        },
        {
          key: 'remove',
          label: 'Elimina',
          icon: 'mdi-delete',
          color: 'red',
          onItemCondition: (item) =>
            !item.system && this.canUser('tickets_statuses', 'delete'),
          handler: this.remove,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...mapActions('ticketsStatuses', ['removeItem']),
    ...mapMutations('ticketsStatuses', {
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),

    openModal(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },

    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
    async remove(item) {
      const res = await this.$dialog.confirm({
        text:
          "Proseguire con l'eliminazione del stato ?\n l'azione non è reversibile!",
        title: 'Attenzione',
      })

      if (!res) return

      await this.removeItem(item.id)
      this.$refs.table.refresh()
    },
  },
}
</script>
