<template>
  <Form
    :hide-reset="edit"
    :title="title"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <v-row dense>
      <FormItem
        v-model="code"
        type="text"
        name="Nome stato"
        rules="required"
        :disabled="system && edit && initialSystem"
      />
      <FormItem v-model="active" type="switch" name="Abilitato" />
      <FormItem
        v-if="!edit || !initialSystem"
        v-model="system"
        type="switch"
        hint="Gli stati di sistema non possono più essere modificati."
        :persistent-hint="true"
        name="Di sistema"
      />
      <FormColorPicker v-model="color" />
    </v-row>

    <v-row dense>
      <FormItem v-model="opening" type="switch" name="Stato Apertura" dense />
      <FormItem v-model="closing" type="switch" name="Chiusura" dense />
      <FormItem v-model="suspend" type="switch" name="Sospensione" />

      <FormItem v-model="reschedulable" type="switch" name="Rimodulabile" />
      <FormItem v-model="revocable" type="switch" name="Revocabile" />
    </v-row>

    <v-divider class="my-3" />

    <v-row dense>
      <FormItem
        v-model="macros"
        :values="macrosByContext({ category: categories })"
        type="autocomplete"
        deletable-chips
        name="Limita alle commesse"
        :cols="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        @input="updateCategories"
      />
    </v-row>
    <v-row dense>
      <FormItem
        v-model="categories"
        :values="categoriesByContext({ macro: macros })"
        :disabled="!macros.length"
        type="autocomplete"
        deletable-chips
        name="Limita alle categorie"
        :cols="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
      />
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormColorPicker from '@components/forms/FormColorPicker.vue'

const { mapFields } = createHelpers({
  getterType: 'ticketsStatuses/getCurrent',
  mutationType: 'ticketsStatuses/SET_CURRENT_FIELDS',
})

export default {
  name: 'TicketStatusesForm',
  components: {
    FormColorPicker,
    FormItem,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  computed: {
    ...mapFields([
      'active',
      'categories',
      'closing',
      'code',
      'color',
      'extra',
      'id',
      'macros',
      'opening',
      'reschedulable',
      'revocable',
      'suspend',
      'system',
    ]),
    ...mapState('ticketsStatuses', {
      id: (state) => state.current.id,
      loading: (state) => state.loading,
    }),
    ...mapGetters('ticketsActivities', {
      macrosByContext: 'macrosByContext',
      categoriesByContext: 'categoriesByContext',
    }),
  },
  data() {
    return {
      // Saves the initial value for system so the toggle doesn't disappear
      initialSystem: null,
    }
  },
  watch: {
    /**
     * When the status is loaded store the initial system
     * value so the toggle doesn't disappear
     */
    id: {
      immediate: true,
      handler: function (val, previous) {
        this.initialSystem = this.system
      },
    },
  },
  methods: {
    ...mapMutations('ticketsStatuses', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('ticketsStatuses', [
      'create',
      'update',
      'setCurrent',
      'remove',
      'getItems',
    ]),
    async onSubmit() {
      try {
        this.$emit('submit')

        if (!this.edit) {
          await this.create()
        } else {
          await this.update()
        }

        this.$emit('submitted', this.id)
      } catch (err) {
        console.log('errore', err)
      }
    },
    onReset() {
      const current = this.edit ? this.id : null
      this.reset()
      if (current) this.setCurrent(current)
      this.$emit('resetted')
    },
    updateCategories(macros) {
      if (!macros.length) this.categories = []
    },
  },
}
</script>

<style scoped></style>
