var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-lg-10",attrs:{"fluid":""}},[_c('TicketsStatusesSearch',{on:{"search":function () { return _vm.$refs.table.refresh(); }}}),_c('BaseTable',{ref:"table",attrs:{"title":"Stati","module":"ticketsStatuses","headers":_vm.headers,"actions":_vm.actions,"sortby":"code","sort-desc":false},scopedSlots:_vm._u([{key:"item.opening",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.opening ? 'SI' : 'NO')+" ")]}},{key:"item.closing",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.closing ? 'SI' : 'NO')+" ")]}},{key:"item.reschedulable",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reschedulable ? 'SI' : 'NO')+" ")]}},{key:"item.revocable",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.revocable ? 'SI' : 'NO')+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active ? 'SI' : 'NO')+" ")]}},{key:"item.suspend",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.suspend ? 'SI' : 'NO')+" ")]}},{key:"actions",fn:function(){return [(_vm.canUser('tickets_statuses', 'create'))?_c('v-btn',{attrs:{"color":"green","dark":"","tile":""},on:{"click":_vm.openModal}},[_vm._v(" Aggiungi stato ")]):_vm._e()]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"75%","retain-focus":false},on:{"click:outside":_vm.modalClose},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.editMode)?_c('v-card-title',[_vm._v(" Modifica Dettagli")]):_c('v-card-title',[_vm._v("Crea nuovo stato")]),_c('v-card-text',[_c('TicketStatusesForm',{attrs:{"title":null,"edit":_vm.editMode},on:{"submitted":_vm.onSubmitted}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }