<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
      <v-text-field
        :clearable="clearable"
        :dense="dense"
        :disabled="disabled"
        :error-messages="errors"
        :hint="hint"
        :label="label || name"
        filled
        :persistent-hint="persistentHint"
        :value="value"
        @input="onInput"
      >
        <template v-slot:append>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            nudge-bottom="105"
            nudge-left="16"
            top
          >
            <template v-slot:activator="{ on }">
              <div :style="swatchStyle" v-on="on" />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker
                  dot-size="10"
                  flat
                  hide-inputs
                  mode="hexa"
                  show-swatches
                  swatches-max-height="100"
                  :value="value"
                  @input="onInput"
                />
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
      </v-text-field>
    </ValidationProvider>
  </v-col>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'FormColorPicker',
  components: {
    ValidationProvider,
  },
  props: {
    clearable: {
      default: false,
      type: Boolean,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    cols: {
      default: 12,
      type: Number,
    },
    sm: {
      default: null,
      type: Number,
    },
    md: {
      default: 3,
      type: Number,
    },
    lg: {
      default: null,
      type: Number,
    },
    xl: {
      default: null,
      type: Number,
    },
    name: {
      default: 'Colore',
      required: false,
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: null,
      required: false,
      type: [String, Object],
    },
    value: {
      default: null,
      type: [String, Array, Number, Object],
    },
  },

  data: () => ({
    color: '',
    menu: false,
  }),
  computed: {
    swatchStyle() {
      const { value, menu } = this
      return {
        backgroundColor: value,
        cursor: 'pointer',
        height: '24px',
        width: '30px',
        border: '1px solid #a8a8a8',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
      }
    },
  },
  methods: {
    onInput(color) {
      this.$emit('input', color)
    },
  },
}
</script>
